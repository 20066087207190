import React from 'react';
import './App.css';
import Headers from './components/Headers';
import SubjectList from './components/SubjectList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MatrixPartOne from './components/MatrixPartOne';
import QuizMatrixOne from './components/QuizMatrixOne';
import MatrixPartTwo from './components/MatriksPartTwo';
import QuizMatrixTwo from './components/QuizMatrixTwo';

function App() {
    return (
      <Router>
          <Routes>
              <Route path="/"  element={<><Headers /><SubjectList /></>}/>
              <Route path="/MatrixPartOne" element={<MatrixPartOne/>}/>
              <Route path="/MatrixQuizOne" element={<QuizMatrixOne/>}/>
              <Route path="/MatrixPartTwo" element={<MatrixPartTwo/>}/>
              <Route path="/MatrixQuizTwo" element={<QuizMatrixTwo/>}/>
          </Routes>
      </Router>
  );
}

export default App;
