import './Toggle.css'
import {Component} from "react";

class Toggle extends Component<{ handleChecked: any, isChecked: any }> {
    render() {
        let {handleChecked, isChecked} = this.props;
        return (
            <div className="ToggleContainer">
                <input
                    type="checkbox"
                    id="check"
                    className="toggle"
                    onChange={handleChecked}
                    checked={isChecked}
                />
                <label htmlFor="check"></label>
            </div>
        )
    }
}

export default Toggle;