import React from "react";
import './HomePage.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocalStorage} from '@uidotdev/usehooks';
import rumusinversmatriks from '../assets/inversmatrix22dan33.jpg';
import contohsoal22 from '../assets/contohsoalinvers22.png';
import contohsoal33 from '../assets/contohsoalinvers33.png';
import contohsoal331 from '../assets/contohsoalinvers331.png';
import contohsoal332 from '../assets/contohsoalinvers332.png';
import contohsoal333 from '../assets/contohsoalinvers333.png';
import contohsoal334 from '../assets/contohsoalinvers334.png';


function MatrixPartTwo() {
    const [isDark] = useLocalStorage("isDark", false);
    return (<body className="App-CourseList" id="body" data-theme={isDark ? "dark" : "light"}>
    <main>
        <div className="Background-Text TextPadding">
            <div className="Text-Area">
                <div className="bigTextPadding">
                    <h4><strong>Invers dan Adjoint Matriks.</strong></h4>
                </div>
                <h6><strong>Konsep dan Rumus Invers Matriks.</strong></h6>
                <h6>Invers matriks A adalah suatu matriks baru yang berkebalikan dengan matriks A dengan notasi A-1.
                    Jika matriks tersebut dikalikan dengan invers matriksnya, maka akan terbentuk matriks identitas.
                    /</h6>
                <h6>Umumnya, penggunaan matriks ini untuk memecahkan sistem persamaan linier (SPL). Untuk menyelesaikan
                    invers matriks, terdapat beberapa aturan berdasarkan ordo matriks yaitu 2 x 2 dan 3 x 3. Berikut
                    rumus invers matriks:</h6>
                <img src={rumusinversmatriks} alt="Invers Matriks" width="250px "></img>
                <h6>Contoh soal ordo 2x2:</h6>
                <img src={contohsoal22} alt="Invers Matriks" width="250px"></img>
                <h6>Contoh soal ordo 3x3:</h6>
                <h6>Tentukan invers matriks berikut dengan menggunakan adjoin!</h6>
                <img src={contohsoal331} alt="Invers Matriks" width="150px"></img>
                <h6>Tentukan determinan terlebih dahulu:</h6>
                <img src={contohsoal332} alt="Invers Matriks" width="250px"></img>
                <h6>Kemudian kita tentukan adjoin dengan mencari kofaktor matriks A:</h6>
                <img src={contohsoal333} alt="Invers Matriks" width="250px"></img>
                <h6>Setelah itu masukkan kedalam rumus:</h6>
                <img src={contohsoal334} alt="Invers Matriks" width="250px"></img>
                <h6>jadi,</h6>
                <img src={contohsoal33} alt="Invers Matriks" width="250px"></img>
            </div>
            {/* <table>
                        <tbody className="">
                        <tr>
                            <td>
                                <span className="BlueCircle ms-3">a</span>
                            </td>
                            <td>
                                <span className="BlueCircle ms-3">b</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="PinkCircle ms-3">c</span>
                            </td>
                            <td>
                                <span className="BlueCircle ms-3">d</span>
                            </td>
                        </tr>
                        </tbody>
                    </table> */}
            <div className="align-content-center d-flex justify-content-center paddingbutton">
                <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="/MatrixQuizTwo">Quiz</a>
                <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="#">Next Subject</a>
            </div>
        </div>
    </main>
    </body>)
}

export default MatrixPartTwo;
