import React from "react";
import './HomePage.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocalStorage} from '@uidotdev/usehooks';
import matrixordo22 from '../assets/determinan1.png';
import contohordo22 from '../assets/determinan2.png';
import jawabancontohordo22 from '../assets/contohsoaldeterminan2.png';
import matrixordo33 from '../assets/determinan4.png';
import aturansarrus from '../assets/aturansarrus.jpg';
import minorfaktor1 from '../assets/minorfaktor1.jpg';
import minorfaktor2 from '../assets/minorfaktor2.jpg';
import minorfaktor3 from '../assets/minorfaktor3.jpg';
import minorfaktor4 from '../assets/minorfaktor4.jpg';
import contohsoaldet33 from '../assets/contohsoaldet33.png';
import contohsoaldet331 from '../assets/contohsoaldet331.png';
import contohsoaldet332 from '../assets/contohsoaldet332.png';
import contohminorfaktor1 from '../assets/contohminorfaktor1.png';
import contohminorfaktor2 from '../assets/contohminorfaktor2.png';
import contohminorfaktor3 from '../assets/contohminorfaktor3.png';
import contohminorfaktor4 from '../assets/contohminorfaktor4.png';
import contohminorfaktor5 from '../assets/contohminorfaktor5.png';
import contohminorfaktor6 from '../assets/contohminorfaktor6.png';


function MatrixPartOne() {
    const [isDark] = useLocalStorage("isDark", false);
    return (<body className="App-CourseList" id="body" data-theme={isDark ? "dark" : "light"}>
        <main>
            <div className="Background-Text TextPadding">
                <div className="Text-Area">
                    <div className="bigTextPadding">
                        <h4><strong>Cara Mencari Determinan Matriks</strong></h4>
                    </div>
                    <h6>Determinan adalah nilai yang dapat dihitung dari unsur-unsur suatu matriks persegi.</h6>
                    <h6>Matriks persegi adalah matriks yang memiliki jumlah baris dan kolom yang sama, sehingga kalau
                        kita gambarkan bentuk matriksnya, akan membentuk bangun layaknya persegi.</h6>
                    <h6>Determinan matriks A bisa ditulis dengan tanda <strong>det (A)</strong>, <strong>det A</strong>,
                        atau <strong>|A|</strong>.</h6>
                    <div className="bigTextPadding">
                        <h5><strong>A. Determinan Matriks Ordo 2x2.</strong></h5>
                    </div>
                    <h6>Misalkan, <img src={matrixordo22} alt="determinan 2x2" width="100px"></img> adalah matriks
                        berordo 2 x 2. Elemen a dan d terletak pada diagonal utama, sedangkan elemen b dan c terletak
                        pada diagonal kedua. Determinan matriks A dapat diperoleh dengan mengurangkan hasil kali
                        elemen-elemen diagonal utama dengan hasil kali elemen-elemen diagonal kedua.</h6>
                    <h6 className="bigTextPadding">Contoh:</h6>
                    <h6>Tentukan determinan matriks berikut!</h6>
                    <img src={contohordo22} alt="contoh soal"></img>
                    <h6>Pembahasan.</h6>
                    <img src={jawabancontohordo22} alt="jawanban contoh soal" width="250px"></img>
                    <div className="bigTextPadding">
                        <h5><strong>B. Determinan Matrix Ordo 3x3.</strong></h5>
                    </div>
                    <h6>Misalkan, <img src={matrixordo33} alt="determinan 3x3" width="100px"></img> adalah matriks
                        berordo 3 x 3. Terdapat dua cara yang bisa dilakukan untuk mencari determinannya, yaitu
                        menggunakan aturan Sarrus dan metode minor-kofaktor.</h6>
                    <h6 className="bigTextPadding">Aturan Sarrus:</h6>
                    <img src={aturansarrus} alt="Aturan Sarrus" width="250px"></img>
                    <h6 className="bigTextPadding">Metode Minor-Faktor:</h6>
                    <img src={minorfaktor1} alt="Minor Faktor"></img>
                    <h6>Minor suatu matriks dinotasikan dengan huruf M yang telah dihapuskan baris ke-i dan kolom ke-j.
                        Kemudian matriks minor tersebut ditentukan dengan menghitung determinannya menggunakan perkalian
                        silang pada matriks tersebut.</h6>
                    <img src={minorfaktor2} alt="Minor Faktor" width="250px"></img>
                    <h6>Kofaktor matriks dinotasikan dengan huruf K atau C, yang mana penentuan positif dan negatif
                        diperoleh dari hasil (-1) dipangkatkan dengan jumlah baris dan kolom sesuai posisi kofaktor yang
                        ingin dicari.</h6>
                    <img src={minorfaktor3} alt="Minor Faktor" width="250px"></img>
                    <h6>Perhitungan determinan dibagi menjadi ekspansi baris dan kolom. Determinan diperoleh dengan
                        perkalian antara elemen matriks semula dengan kofaktornya pada 1 baris atau 1 kolom yang telah
                        kita tentukan.</h6>
                    <img src={minorfaktor4} alt="Minor Faktor" width="250px"></img>
                    <h6 className="bigTextPadding">Contoh:</h6>
                    <h6>Tentukan determinan matriks berikut ini menggunakan aturan Sarrus dan metode
                        minor-kofaktor!</h6>
                    <img src={contohsoaldet33} alt="Determinan 3x3"></img>
                    <h6 className="bigTextPadding">Pembahasan.</h6>
                    <h6>Aturan Sarrus:</h6>
                    <h6>Agar lebih mudah, kita tulis kembali elemen-elemen pada kolom ke-1 dan ke-2 di sebelah kanan
                        matriks A sebagai berikut:</h6>
                    <img src={contohsoaldet331} alt="Determinan 3x3" width="250px"></img>
                    <h6>Kemudian, kita tarik garis putus-putus seperti gambar di atas. Kalikan elemen-elemen yang
                        terkena garis putus-putus tersebut. Hasil kali elemen yang terkena garis putus-putus berwarna
                        biru diberi tanda positif (+), sedangkan hasil kali elemen yang terkena garis putus-putus
                        berwarna oranye diberi tanda negatif (-). Ingat urutan penulisannya juga, ya!</h6>
                    <img src={contohsoaldet332} alt="Determinan 3x3" width="250px"></img>
                    <h6>Metode minor-faktor:</h6>
                    <h6>Berdasarkan rumus minor-kofaktor di atas, determinan matriks A dapat dicari dengan menghitung
                        jumlah seluruh hasil kali antara kofaktor matriks bagian dari matriks A dengan elemen-elemen
                        pada salah satu baris atau kolom matriks A. Jadi, pertama, kita pilih salah satu baris atau
                        kolom matriks A untuk mendapatkan nilai determinannya. Misalnya, kita pilih baris ke-1.
                        Elemen-elemen matriks baris ke-1, yaitu a11, a12, dan a13.</h6>
                    <img src={contohminorfaktor1} alt="Determinan 3x3"></img>
                    <h6>Selanjutnya, karena kita pilih elemen-elemen pada baris ke-1, rumus determinan matriks yang kita
                        gunakan adalah sebagai berikut:</h6>
                    <img src={contohminorfaktor2} alt="Determinan 3x3" width="250px"></img>
                    <h6>Langkah kedua, kita cari kofaktor matriks bagian dari matriks A (Cij). Cij = (-1)i+j Mij dan Mij
                        = det Aij dengan Aij merupakan matriks bagian dari matriks A yang diperoleh dengan menghilangkan
                        baris ke-i dan kolom ke-j. Maksudnya bagaimana? Oke, coba kamu perhatikan baik-baik ya.</h6>
                    <h6>Sebelumnya, kita telah memilih elemen-elemen pada baris ke-1, yaitu a11, a12, dan a13. Oleh
                        karena itu, matriks bagian dari matriks A nya adalah A11, A12, dan A13.</h6>
                    <h6 className="bigTextPadding">A11 diperoleh dengan menghilangkan elemen-elemen pada baris ke-1 dan
                        kolom ke-1.</h6>
                    <img src={contohminorfaktor3} alt="Determinan 3x3" width="250px"></img>
                    <h6 className="bigTextPadding">A12 diperoleh dengan menghilangkan elemen-elemen pada baris ke-1 dan
                        kolom ke-2.</h6>
                    <img src={contohminorfaktor4} alt="Determinan 3x3" width="250px"></img>
                    <h6 className="bigTextPadding">A13 diperoleh dengan menghilangkan elemen-elemen pada baris ke-1 dan
                        kolom ke-3.</h6>
                    <img src={contohminorfaktor5} alt="Determinan 3x3" width="250px"></img>
                    <h6>Sehingga,</h6>
                    <img src={contohminorfaktor6} alt="Determinan 3x3" width="250px"></img>
                </div>
                {/* <table>
                        <tbody className="">
                        <tr>
                            <td>
                                <span className="BlueCircle ms-3">a</span>
                            </td>
                            <td>
                                <span className="BlueCircle ms-3">b</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="PinkCircle ms-3">c</span>
                            </td>
                            <td>
                                <span className="BlueCircle ms-3">d</span>
                            </td>
                        </tr>
                        </tbody>
                    </table> */}
                <div className="align-content-center d-flex justify-content-center paddingbutton">
                    <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="/MatrixQuizOne">Quiz</a>
                    <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="/MatrixPartTwo">Next Subject</a>
                </div>
            </div>
        </main>
        </body>)
}

export default MatrixPartOne;
