import React from "react";
import matrix from '../assets/matrix.png';
import './HomePage.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function SubjectList() {
    return (
        <body className="App-CourseList" id="body">
        <p className="paddingjudul">Math Subject</p>
        <div className="row justify-content-center">
            <div className="col-4 col-lg-2 col-md-4 mb-4 d-flex align-items-stretch">
                <div className="card position-relative">
                    <a href="/MatrixPartOne"><img className="card-img-top" src={matrix} alt="matrix"></img></a>
                    <div className="card-body">
                        <h5 className="card-title text-center">Matrix</h5>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
        </body>
    )
}

export default SubjectList;
