import React, {useState} from "react";
import './Quiz.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocalStorage} from "@uidotdev/usehooks";
import soal1 from "../assets/soalpart2-1.jpg";
import soal2 from "../assets/soalpart2-2.jpg";
import soal3 from "../assets/soalpart2-3.jpg";
import soal4 from "../assets/soalpart2-4.jpg";
import soal5 from "../assets/soalpart2-5.jpg";

function QuizMatrixTwo() {
    const [isDark] = useLocalStorage("isDark", false);
    const questions = [
        {
            questionText: '',
            image: soal1,
            answerOptions: [
                { answerText: 'A', isCorrect: true },
                { answerText: 'B', isCorrect: false },
                { answerText: 'C', isCorrect: false },
                { answerText: 'D', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal2,
            answerOptions: [
                { answerText: '22', isCorrect: false },
                { answerText: '-22', isCorrect: true },
                { answerText: '14', isCorrect: false },
                { answerText: '-14', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal3,
            answerOptions: [
                { answerText: '10', isCorrect: false },
                { answerText: '-6', isCorrect: true },
                { answerText: '4', isCorrect: false },
                { answerText: '7', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal4,
            answerOptions: [
                { answerText: 'A', isCorrect: true },
                { answerText: 'B', isCorrect: false },
                { answerText: 'C', isCorrect: false },
                { answerText: 'D', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal5,
            answerOptions: [
                { answerText: 'Matriks adjoint B adalah matriks identitas.', isCorrect: false },
                { answerText: 'Matriks adjoint B adalah matriks nol.', isCorrect: true },
                { answerText: 'Matriks adjoint B sama dengan matriks B itu sendiri.', isCorrect: false },
                { answerText: 'Matriks adjoint B tidak dapat dihitung karena determinantnya nol.', isCorrect: false },
            ],
        },
    ];

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);

    const handleAnswerOptionClick = (isCorrect: boolean) => {
        if (isCorrect) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };
    return (
        <body className="Quizbody" data-theme={isDark ? "dark" : "light"}>
        <div className='quiz'>
            {showScore ? (
                <div className='score-section'>
                    <div className="TextPadding">
                        <h3>
                            You scored {score} out of {questions.length}
                        </h3>
                        <div className="align-content-center d-flex justify-content-center paddingbutton">
                            <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="/#">Next Subject</a>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='question-section'>
                        <div className='question-count'>
                            <span>Question {currentQuestion + 1}</span>/{questions.length}
                        </div>
                        <div className='question-text'>{questions[currentQuestion].questionText}<img className="soal"
                                                                                                     src={questions[currentQuestion].image}
                                                                                                     alt="Soal"
                                                                                                     width="200px"></img>
                        </div>
                    </div>
                    <div className='answer-section'>
                        {questions[currentQuestion].answerOptions.map((answerOption) => (
                            <button
                                onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
                        ))}
                    </div>
                </>
            )}
        </div>
        </body>
    );
}

export default QuizMatrixTwo;
