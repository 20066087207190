import React from "react";
import logo from '../assets/plus-512.png';
import './HomePage.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Toggle from "./Toggle";
import './Toggle.css'
import { useLocalStorage } from "@uidotdev/usehooks"

function Headers() {
    const [isDark, setDark] = useLocalStorage("isDark", false);

    return (
        <div className="headers" data-theme={isDark ? "dark" : "light"}>
            <header className="App-header">
                <Toggle isChecked={isDark} handleChecked={() => setDark(!isDark)}/>
                <img src={logo} className="App-logo" alt=""/>
                <p>
                    Math with MathHub
                </p>
                <a className="btn btn-primary btn-lg btn-danger" type="button" href="#body">Math Subject</a>

            </header>
        </div>
    )
}

export default Headers;
