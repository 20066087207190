import React, {useState} from "react";
import './Quiz.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocalStorage} from "@uidotdev/usehooks";
import soal1 from '../assets/soalpart1-1.jpg';
import soal2 from '../assets/soalpart1-2.jpg';
import soal3 from '../assets/soalpart1-3.jpg';
import soal4 from '../assets/soalpart1-4.jpg';
import soal5 from '../assets/soalpart1-5.jpg';

function QuizMatrixOne() {
    const [isDark] = useLocalStorage("isDark", false);
    const questions = [
        {
            questionText: '',
            image: soal1,
            answerOptions: [
                { answerText: '11', isCorrect: false },
                { answerText: '5', isCorrect: true },
                { answerText: '-5', isCorrect: false },
                { answerText: '-11', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal2,
            answerOptions: [
                { answerText: '136', isCorrect: false },
                { answerText: '180', isCorrect: false },
                { answerText: '230', isCorrect: false },
                { answerText: '218', isCorrect: true },
            ],
        },
        {
            questionText: '',
            image: soal3,
            answerOptions: [
                { answerText: '-2', isCorrect: false },
                { answerText: '-7', isCorrect: true },
                { answerText: '7', isCorrect: false },
                { answerText: '2', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal4,
            answerOptions: [
                { answerText: '0', isCorrect: true },
                { answerText: '1', isCorrect: false },
                { answerText: '-1', isCorrect: false },
                { answerText: '45', isCorrect: false },
            ],
        },
        {
            questionText: '',
            image: soal5,
            answerOptions: [
                { answerText: '-21', isCorrect: true },
                { answerText: '21', isCorrect: false },
                { answerText: '-17', isCorrect: false },
                { answerText: '17', isCorrect: false },
            ],
        },
    ];

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);

    const handleAnswerOptionClick = (isCorrect: boolean) => {
        if (isCorrect) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };
    return (
        <body className="Quizbody" data-theme={isDark ? "dark" : "light"}>
            <div className='quiz'>
            {showScore ? (
                <div className='score-section'>
                    <div className="TextPadding">
                        <h3>
                            You scored {score} out of {questions.length}
                        </h3>
                        <div className="align-content-center d-flex justify-content-center paddingbutton">
                            <a className="btn btn-primary btn-lg btn-danger ms-3" type="button" href="/MatrixPartTwo">Next Subject</a>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='question-section'>
                    <div className='question-count'>
                            <span>Question {currentQuestion + 1}</span>/{questions.length}
                        </div>
                        <div className='question-text'>{questions[currentQuestion].questionText}<img className="soal"
                                                                                                      src={questions[currentQuestion].image}
                                                                                                      alt="Soal"
                                                                                                      width="200px"></img>
                        </div>
                    </div>
                    <div className='answer-section'>
                        {questions[currentQuestion].answerOptions.map((answerOption) => (
                            <button onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
                        ))}
                    </div>
                </>
            )}
        </div>
        </body>
    );
}

export default QuizMatrixOne;
